//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TriangleCustomRate from '$resources/images/triangle-custom-rate.png';

export default {
  filters: {
    formatNumber(value) {
      return new Intl.NumberFormat('de-DE').format(Number(value).toFixed(2));
    }
  },
  props: {
    selected: Boolean,
    offer: Object,
    hiredPower: String,
    variant: {
      type: String,
      validator: v => ['common', 'without-wattage'].includes(v),
      default: 'common'
    }
  },
  data: () => ({
    TriangleCustomRate
  }),
  computed: {
    ratesDescription() {
      switch (this.offer.idTarifaPersonalizada) {
        case '11':
          return [
            'Paga por lo que consumes, según tus necesidades.',
            'Máxima flexibilidad, ahorra en las horas más baratas.',
            'Energía 100% verde.'
          ];
        case '10':
          return [
            'Paga por lo que consumes, según tus necesidades.',
            'Máxima flexibilidad para tu negocio, ahorra en las horas más baratas.',
            'Energía 100% verde.'
          ];
        case '12':
        case '15':
        case '16':
        case '27':
          return [
            'Pagarás lo mismo en todas las horas. Sin horarios, sin molestias, disfruta de la estabilidad de saber cuánto pagarás.',
            'Energía 100% verde.'
          ];
        case '13':
          return [
            'Un precio para cada periodo (punta, llano y valle). Aprovecha la oportunidad de ahorro según la franja horaria.',
            'Energía 100% verde.'
          ];
        case '17':
        case '18':
        case '26':
          return [
            'Un precio para cada periodo. Aprovecha la oportunidad de ahorro para tu negocio según la franja horaria.',
            'Energía 100% verde.'
          ];
        case '32':
          return [
            'Máxima flexibilidad, ahorra en las horas más baratas.',
            'Sólo añadimos un cargo de gestión de 0,02 €/kWh. Lo demás a precio de coste.'
          ];
        case '37':
          return [
            'Pagarás lo mismo en todas las horas. Sin horarios, sin molestias, disfruta de la estabilidad de saber cuánto pagarás.',
            'Energía 100% verde.'
          ];
        case '31':
          return [
            'Máxima flexibilidad para tu negocio, ahorra en las horas más baratas.',
            'Sólo añadimos un cargo de gestión de 0,02 €/kWh.'
          ];
        case '43':
          return [
            'Máxima flexibilidad, ahorra en las horas más baratas.',
            'Sólo añadimos un cargo de gestión de 3,99 €/mes.',
            'Energía 100% verde.'
          ];
        case '49':
          return [
            'Pagarás lo mismo en todas las horas. Sin horarios, sin molestias, disfruta de la estabilidad de saber cuánto pagarás',
            'Energía 100% verde.'
          ];
        default:
          return [];
      }
    },
    tarifaNameFormated() {
      if (this.offer.aliasTarifa) {
        return this.offer.aliasTarifa;
      }

      return this.offer.nombreTarifa;
    },
    priceMontlyClassName() {
      return {
        alone: this.offer.ahorroMensual === null,
        small: this.offer.precioMensual
      };
    },
    savingsMontlyClassName() {
      return {
        small: String(this.offer.ahorroMensual.toFixed(2)).length > 5
      };
    }
  }
};
